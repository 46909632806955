<template>
    <div class="container p-2 p-md-2">
        <form class="row g-3">
            <div class="col-6">
                <label for="inputEmail4" class="form-label">Name</label>
                <input type="email" class="form-control" id="inputEmail4">
            </div>
            <div class="col-6">
                <label for="inputPassword4" class="form-label">Age</label>
                <input type="password" class="form-control" id="inputPassword4">
            </div>
            <h2>Personal Bests</h2>
            <div class="col-6">
                <label for="inputMarathon" class="form-label">Marathon</label>
                <input type="text" class="form-control" id="inputMarathon" placeholder="02:59:31">
            </div>
            <div class="col-6">
                <label for="inputHalfMarathon" class="form-label">Half marathon</label>
                <input type="text" class="form-control" id="inputHalfMarathon" placeholder="01:29:10">
            </div>
            <div class="col-6">
                <label for="input10k" class="form-label">10 km</label>
                <input type="text" class="form-control" id="input10k" placeholder="01:29:10">
            </div>
            <div class="col-6">
                <label for="input10000" class="form-label">10 000 m</label>
                <input type="text" class="form-control" id="input10000" placeholder="01:29:10">
            </div>
            <div class="col-md-12">
                <label for="input5000" class="form-label">5000</label>
                <input type="text" class="form-control" id="input5000">
            </div>
            <h2>Goals</h2>
            <div class="col-md-4">
                <label for="inputState" class="form-label">State</label>
                <select id="inputState" class="form-select">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
// import axios from 'axios'
export default {
    name: 'UserData',
    mounted() {

    },
    props: {

    },
    data() {
    }
}
</script>
<style scoped>

</style>