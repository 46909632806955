<template>
  <div>
    <!-- Navigation-->

    <!-- Mashead header-->
    <header class="py-4">
      <div class="container px-md-5">
        <div class="row gx-5 align-items-center">
          <h1 class="display-1 lh-1 mb-3 display-1 lh-1 mb-3 d-sm-block d-md-none">Urris</h1>
          <div class="col-6">
            <!-- Mashead text and app badges-->
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h1 class="display-1 lh-1 mb-3 display-1 lh-1 mb-3 d-none d-lg-block" id="sticky-brand">Urris</h1>
              <p class="lead fw-normal text-muted mb-5 text-start" id="sticky-brand-indicator">Running influencers are long gone. Long live Urris, the AI Coach
                <br> <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="text-info" role="button">Who is Urris and how can he improve my
                  running?</a></p>

              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">Two faced running coach</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="accordion" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseOne">
                              <strong> Urris, the story - </strong>The best of two worlds
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingOne">
                            <div class="accordion-body text-start">

                              Urris was once a rising star of running in sweden. He had always been a natural athlete,
                              and as a teenager, he began
                              to focus his energy on becoming a professional runner. He trained tirelessly, pushing
                              himself to the limit and beyond.
                              <p>
                                In his early career, Urris reached the pinnacle of his sport, winning numerous national
                                and international races,
                                setting new records and becoming one of the elite runners in europe.
                              </p>
                              <p>
                                But as much as he loved running, Urris had always been interested in statistics. He had
                                been fascinated by data analysis
                                since he was a child, and as he grew older, that fascination only deepened. Eventually,
                                Urris realized that he wanted to
                                pursue a career in the field, and so, despite his great success in running, he made the
                                decision to retire from
                                professional athletics and go back to school to study statistics.
                              </p>
                              <p>
                                After completing his education, Urris got a job as a statistician in the insurance
                                industry. He threw himself into his
                                work, and soon, he was making important contributions to the field. But he never stopped
                                running, he continued to train
                                and soon he found that he was running just as well as he ever had before.
                              </p>
                              <p>
                                Then, one day he decided to enter a local marathon, he found that he was still one of
                                the top runners in the country and
                                even won the race. His dedication, hard work, and statistical approach made him an
                                inspiration for many amateur runners,
                                and many started to seek his advice and training methods.
                                Urris was now a successful statistician in the insurance industry and an elite runner,
                                proving that it is possible to
                                excel in multiple areas. He showed them that, with hard work and dedication, anything is
                                possible. He continued to
                                inspire people who were looking to balance multiple passions and careers. Urris was the
                                living example of how you can
                                achieve great things, not by choosing one or the other, but by balancing both.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo">
                              Face 1 - Hard work and fun work
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo">
                            <div class="accordion-body text-start">
                              <p>
                                So the first face of Urris. A former elite runner with a wealth of experience in the
                                world of professional athletics.
                                With a proven track record of success, Urris can help you achieve your running goals
                                through workout suggestions based
                                on your training.
                              </p>
                              <p>
                                He will give you encouragements, workoutcomments and suggestions for beer and food.
                                Whether you're a beginner or a seasoned runner,
                                Urris' expertise and experience can help you improve your performance and reach new
                                personal bests. And best of it, he will make running more fun!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree">
                              Face 2 - Stats
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree">
                            <div class="accordion-body text-start">
                              Second face of Urris is his intrest in statistics. As a statistician in the insurance
                              industry, he is here to help. His expertise in data analysis and statistics can help you gain a deeper understanding of
                              your running stats, giving you the insights you need to improve your performance. Using the latest in statistical
                              techniques, Urris can help you analyze your data and create a customized training plan tailored to your specific goals.
                              Don't leave your running performance to chance, let Urris help you unlock your full potential.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="d-flex flex-column flex-lg-row align-items-center">
                <!-- <a class="me-lg-3 mb-4 mb-lg-0" href="#!"><img class="app-badge" src="assets/img/google-play-badge.svg" alt="..." /></a> -->
                <!-- <a href="#!"><img class="app-badge" src="assets/img/app-store-badge.svg" alt="..." /></a> -->
              </div>
            </div>
          </div>
          <div class="col-6">
            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup">
              <!-- <svg class="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                                        <stop class="gradient-start-color" offset="0%"></stop>
                                        <stop class="gradient-end-color" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <circle cx="50" cy="50" r="50"></circle></svg><svg class="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)"></rect>
                                <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)"></rect></svg><svg class="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"></circle></svg>
                            -->
              <img class="img-fluid" src="avatar-jumpsuit.png" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Quote/testimonial aside-->
    <aside class="text-center bg-gradient bg-primary">
      <div class="container p-5">
        <div class="row gx-5 justify-content-center">
          <div class="col-xl-8">
            <div class="h2 fs-1 text-white mb-4">"A simple solution to a common running related problem! Paying a lot
              for a running coach"</div>
            <!-- <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> -->
          </div>
        </div>
      </div>
    </aside>
    <!-- App features section-->
    <section id="features">
      <div class="container p-5">
        <div class="row gx-5 align-items-md-center flex-row-reverse">
          <div class="col-8 order-lg-1 mb-5 mb-lg-0">
            <div class="container-fluid px-5">
              <div class="row gx-5">
                <div class="col-md-6 mb-5">
                  <!-- Feature item-->
                  
                  <div class="text-center">
                    <i class="bi-gift icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt">Workout suggestions</h3>
                    <p class="text-muted mb-0">Tell Urris your goal and he will give you multiple suggestions for
                      workouts</p>
                  </div>
                </div>
                <div class="col-md-6 mb-5">
                  <!-- Feature item-->
                  <div class="text-center">
                    <i class="bi-camera icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt">Useful insights of your running</h3>
                    <p class="text-muted mb-0">Urris will sneak peak at your Strava sessions and provide pro tip from
                      there</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                  <!-- Feature item-->
                  <div class="text-center">
                    <i class="bi-gift icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt">Almost free</h3>
                    <p class="text-muted mb-0">Urris does not charge any monthly fee. Pay 15 Euro once, and you have him
                      forever</p>
                  </div>
                </div>

                <div class="col-md-6 mb-5 mb-md-0">
                  <!-- Feature item-->
                  <div class="text-center">
                    <i class="bi-phone icon-feature text-gradient d-block mb-3"></i>
                    <h3 class="font-alt">Follow up comments</h3>
                    <p class="text-muted mb-0">Urris will always encourage. BUT he can also be frank, not harsh, but
                      direct and plain</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-4 order-lg-0">
            <!-- Features section device mockup-->
            <img class="img-fluid" src="avatar-science.png" alt="..." />
          </div>
        </div>
      </div>
    </section>
    <!-- Basic features section-->
    <section class="bg-light">
      <div class="container p-5">
        <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
          <div class="col-12 col-lg-5">
            <h2 class="display-4 lh-1 mb-4">Enter a new age of running coach</h2>
            <p class="lead fw-normal text-muted mb-5 mb-lg-0 text-start">All of those running influencers are trying to make you
              feel special, but behind your back provides the exact same schedule for everyone. <br><br>Because of this
              Urris was created. He does the same thing, probably better and definitly cheaper. Best of it. Urris is
              fun.
              He will encourage you to run faster, he will provide you with insightful stats and he will even give you
              cooking advice. For drinking he will always promote beer.
            </p>
          </div>
          <div class="col-sm-8 col-md-6">
            <div class="px-5 px-sm-0"><img class="img-fluid" src="avatar-cook.png" alt="..." /></div>
          </div>
        </div>
      </div>
    </section>
   


    <!-- Feedback Modal-->
    <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-gradient-primary-to-secondary p-4">
            <h5 class="modal-title font-alt text-white" id="feedbackModalLabel">Send feedback</h5>
            <button class="btn-close btn-close-white" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body border-0 p-4">
            <!-- * * * * * * * * * * * * * * *-->
            <!-- * * SB Forms Contact Form * *-->
            <!-- * * * * * * * * * * * * * * *-->
            <!-- This form is pre-integrated with SB Forms.-->
            <!-- To make this form functional, sign up at-->
            <!-- https://startbootstrap.com/solution/contact-forms-->
            <!-- to get an API token!-->
            <form id="contactForm" data-sb-form-api-token="API_TOKEN">
              <!-- Name input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="name" type="text" placeholder="Enter your name..."
                  data-sb-validations="required" />
                <label for="name">Full name</label>
                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
              </div>
              <!-- Email address input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="email" type="email" placeholder="name@example.com"
                  data-sb-validations="required,email" />
                <label for="email">Email address</label>
                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
              </div>
              <!-- Phone number input-->
              <div class="form-floating mb-3">
                <input class="form-control" id="phone" type="tel" placeholder="(123) 456-7890"
                  data-sb-validations="required" />
                <label for="phone">Phone number</label>
                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
              </div>
              <!-- Message input-->
              <div class="form-floating mb-3">
                <textarea class="form-control" id="message" type="text" placeholder="Enter your message here..."
                  style="height: 10rem" data-sb-validations="required"></textarea>
                <label for="message">Message</label>
                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
              </div>
              <!-- Submit success message-->
              <!---->
              <!-- This is what your users will see when the form-->
              <!-- has successfully submitted-->
              <div class="d-none" id="submitSuccessMessage">
                <div class="text-center mb-3">
                  <div class="fw-bolder">Form submission successful!</div>
                  To activate this form, sign up at
                  <br />
                  <a
                    href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                </div>
              </div>
              <!-- Submit error message-->
              <!---->
              <!-- This is what your users will see when there is-->
              <!-- an error submitting the form-->
              <div class="d-none" id="submitErrorMessage">
                <div class="text-center text-danger mb-3">Error sending message!</div>
              </div>
              <!-- Submit Button-->
              <div class="d-grid"><button class="btn btn-primary rounded-pill btn-lg disabled" id="submitButton"
                  type="submit">Submit</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LandingPage',
  mounted() {
    window.onscroll = function () {
        if (window.innerWidth > 780) {
        var stickyElement = document.querySelector("#sticky-brand");
        var stickyElementIndicator = document.querySelector("#sticky-brand-indicator");
        var navUrris = document.querySelector("#nav-urris");

        var sticky = stickyElementIndicator.offsetTop;
        if (window.pageYOffset >= sticky - 25) {
          stickyElement.classList.add("d-none");
          navUrris.classList.remove("d-none");

        } else {
          stickyElement.classList.remove("d-none");
          navUrris.classList.add("d-none");

        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sticky-element {
  position: relative;
}
.sticky {
  position: fixed;
  top: 5px;
  left: 0;
  width: 100%;
  z-index: 999;
}
.small {
  transform: scale(0.8);
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
