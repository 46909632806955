<template>
  
  <div>
    <LayoutLoggedIn v-if="authenticatedStore.authenticated">
      <router-view></router-view>
    </LayoutLoggedIn>
    <LayoutPublic v-else>
      <router-view></router-view>
    </LayoutPublic>
  </div>
</template>

<script>

import LayoutPublic from './components/Layout/LayoutPublic';
import LayoutLoggedIn from './components/Layout/LayoutLoggedIn';
import { useAuthenticatedStore } from '@/stores/authenticated'

export default {
  name: 'App',
  components: {
    LayoutPublic,
    LayoutLoggedIn
  },
  data() {
    return {
      authenticatedStore: useAuthenticatedStore()
    }
  }
}
</script>

<style>

@media (max-width: 768px) {
    :root{font-size: 13px;}
}

@media (max-width: 992) {
    :root{font-size: 14px;}
}


h5{
    font-size: 1.4rem;
} 

</style>
