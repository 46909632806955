<template>
    <div id="land-app">  
        <header-public></header-public>
        <slot></slot>
        <Footer></Footer>
    </div>
</template>

<script>
import HeaderPublic from './Header/HeaderPublic.vue'
import Footer from './Footer/Footer.vue'

export default {
  name: 'LayoutPublic',
  components: {
    HeaderPublic,
    Footer
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  :root {
    font-size: 12px;
  }

  #land-app {
    margin-bottom: 60px
  }
}
@media (min-width: 768px) {
  #land-app {
  
    margin-top: 60px;
  }

}
@media (max-width: 992px) {
  :root {
    font-size: 14px;
  }

}
</style>