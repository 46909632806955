import { createApp } from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import LandingPage from './components/LandingPage';
import AthleteDashboard from './components/AthleteDashboard';
import ActivityDetails from './components/ActivityDetails';
import UrrisComment from './components/UrrisComment';
import UserData from './components/UserData';
import { createPinia } from 'pinia' // Import
import VueCookies from 'vue3-cookies'
import { useAuthenticatedStore } from '@/stores/authenticated'
import { importFontAwesomeIcons } from './importedIcon';
import formatTime from './plugins/format-time-plugin';
import formatMpsToMpk from './plugins/format-mps-to-mpk-plugin';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'

axios.defaults.baseURL = location.origin.includes("localhost") ? "http://localhost:5000" : "https://www.urris.me"

importFontAwesomeIcons();

const routes = [
  { path: '/', component: LandingPage },
  {
    path: '', component: LandingPage,
    meta: {
      publicRoute: true,
      title: 'Urris - Runningcoach',
      metaTags: [
        {
          name: 'description',
          content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
        },
        {
          property: 'og:description',
          content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
        },
        {
          property: 'og:image',
          content: '@/avatar-face.png"'
        }
      ]
    }
  },
  { path: '/user', component: AthleteDashboard,
  meta: {
    publicRoute: true,
    title: 'Urris - Runningcoach',
    metaTags: [
      {
        name: 'description',
        content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
      },
      {
        property: 'og:description',
        content: 'Urris, a smart running coach that makes running more fun and fullfills your potential'
      },
      {
        property: 'og:image',
        content: '@/avatar-face.png"'
      }
    ]
  } 
},
  {
    path: '/user/info',
    component: UserData,
  },
  {
    path: '/newuser',
    component: UrrisComment,
    props:{comment: 'Welcome, I will take a look at your stats and be back with full functionality in a very short while. Check your email in like 5 minutes!!', widthstyle: 150}
  },
  {
    path: '/user/activity/:id',
    component: ActivityDetails,
  }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});
const vueApp = createApp(App);
vueApp.component('font-awesome-icon', FontAwesomeIcon);
vueApp.use(VueCookies);
vueApp.use(formatTime);
vueApp.use(formatMpsToMpk);
vueApp.use(createPinia());
vueApp.use(router);

const authenticatedStore = useAuthenticatedStore(); 
router.beforeEach((to, from, next) => {
  document.title = "Urris Running Coach"
  var loggedIn = authenticatedStore.getLoggedIn();
  const { $cookies } = vueApp.config.globalProperties;
  if((!$cookies.get('authenticated') || $cookies.get('authenticated') !== 'true') && (to.path !== '' && to.path !== '/')){
    authenticatedStore.authenticated = false;
    next('');
    return;
  }
  else if($cookies.get('authenticated') === 'true' && !loggedIn) {
    authenticatedStore.authenticated = true;
    if(to.path.indexOf('newuser') > 0) {
      next('/newuser')
    }
    else{
      next('/user')
    }
    
    return;

  }
  else if(!$cookies.get('authenticated')){
    authenticatedStore.authenticated = false;
  }
    next()
    return;
});

vueApp.mount('#app');
