<template>

  <div>
    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Development over time</h6>
        <div class="ms-auto">{{ selectedDataset }}</div>
        <div class="dropdown no-arrow">
          <a class="dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
            <div class="dropdown-header">Development parameter</div>
            <div v-for="dataset in datasets" @click="updateSelectedDataset(dataset.label)" :key="dataset.label"
              :value="dataset.label" class="dropdown-item">{{
                dataset.label
              }}</div>

          </div>
        </div>
      </div>
      <!-- Card Body -->
      <div class="card-body">
        <div class="chart-area">
          <div id="chart-dev" style="width: 100%; overflow-x: scroll; overflow-y: hidden; max-width: 82vw;">
            <div :style="{ width: (labels.length * 30) + 'px', height: '400px' }">
              <canvas ref="chart" height="400" width="0"></canvas>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Chart from 'chart.js'
import axios from 'axios'

export default {
  name: 'DevelopmentOverTime',

  data() {
    return {
      chart: null,
      datasets: [],
      labels: [],
      selectedDataset: 'Sum Distance',
      rectangleSet: false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.get('api/athletedashdevovertime')
        .then(response => {
          this.labels = response.data.map(datapoint => `${this.formatDate(datapoint.start_date)} to ${this.formatDate(datapoint.end_date)}`)
          this.datasets = [
            {
              label: 'Sum Distance',
              data: response.data.map(datapoint => datapoint.sum_distance),
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,

            },
            {
              label: 'Average Speed',
              data: response.data.map(datapoint => datapoint.avg_speed),
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,

            },
            {
              label: 'Race Results',
              data: response.data.map(datapoint => datapoint.race_count),
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,

            }
          ]
          this.renderChart()
        })
    },

    updateSelectedDataset(selectedDataset) {
      this.selectedDataset = selectedDataset;
      this.chart.data.datasets = this.selectedDataset ? [this.datasets.find(dataset => dataset.label === this.selectedDataset)] : this.datasets;
      this.chart.update()
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    renderChart() {
      this.chart = new Chart(this.$refs.chart, {
        maintainAspectRatio: false,
        responsive: true,
        type: 'line',
        data: {
          labels: this.labels,
          datasets: this.selectedDataset ? [this.datasets.find(dataset => dataset.label === this.selectedDataset)] : this.datasets
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          animation: {
            onComplete: () => {
              setTimeout(function () {

                const myElement = document.getElementById("chart-dev");
                myElement.scrollLeft = myElement.scrollWidth;


              }, 100)
            },
          }
        },
      });




    }
  }
}
</script>
<style scoped>
.chartWrapper {
  position: relative;
}

.chartWrapper>canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}
</style>