<template>
  <div class="dashboard container-fluid px-lg-5">
    <link
        href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
        rel="stylesheet">
        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

<!-- Main Content -->
<div id="content">

    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <UrrisComment :comment="'Hi '+ athlete.name + ', looking like your shape is moving in the right direction, keep it up!'" :widthstyle="70"></UrrisComment>
        </div>

        <!-- Content Row -->
        <div class="row">
            <div class="col-xl-3 col-6 mb-4">
                <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Last 7 days of running</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{athlete.last_seven_days_of_running}} km</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-calendar fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-6 mb-4">
                <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Number of kilometers in goalpace span</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">6</div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-6 mb-4">
                <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Fitness level to reach goal
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                    </div>
                                    <div class="col">
                                        <div class="progress progress-sm mr-2">
                                            <div class="progress-bar bg-info" role="progressbar"
                                                style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-6 mb-4">
  <div class="card border-left-{{ athlete.injury_risk_color }} shadow h-100 py-2">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col mr-2">
          <div class="text-xs font-weight-bold text-uppercase mb-1">
            Injury danger
          </div>
          <div class="text-xs mb-0 font-weight-bold text-gray-800">
           
              <p>
                {{ athlete.injury_risk?.injury_risk_explanation }}
              </p>
          </div>
          <a class="btn btn-link btn-sm mt-3" data-bs-toggle="injury-explanation" role="button" aria-expanded="false" aria-controls="injury-explanation">
            Explanation
          </a>
          <div class="collapse mt-3" id="injury-explanation">
            <div class="mt-3 small">
                <p>
                Distance risk level: {{ athlete.injury_risk?.distance_risk_level }}
                <br>
                Moving risk level: {{ athlete.injury_risk?.moving_risk_level }}
              </p>
                This athlete's injury risk was calculated based on their recent training data. The distance and moving parameters were analyzed to determine their current injury risk level.
            </div>
          </div>
        </div>
        <div class="col-auto">
          <i class="fas fa-comments fa-2x text-gray-300"></i>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>

        <!-- Content Row -->

        <div class="row">

            <!-- Area Chart -->
            <div class="col-xl-8 col-lg-7 col-xs-12">
                            <DevelopmentOverTime></DevelopmentOverTime>
                      
            </div>

            <!-- Pie Chart -->
            <div class="col-xl-4 col-lg-5">
                <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Last five runs</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">

                            <ActivityCard class="my-2 col-12 col-md-6 d-block w-100" v-for="activity in athlete.activities" :key="activity.id" :activity="activity"></ActivityCard>

                    </div>
                </div>
            </div>
        </div>

        <!-- Content Row -->
        <div class="row">

            <!-- Content Column -->
            <div class="col-lg-6 mb-4">
                <AthletePrs :pr5km="fivekPr" :pr10km="tenkPr" :prHalfMarathon="halfMarathonkPr" :prMarathon="marathonkPr"></AthletePrs>
            </div>

            <div class="col-lg-6 mb-4">

                <!-- Illustrations -->
                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">Upcoming races</h6>
                    </div>
                    <div class="card-body">
                        
                    </div>
                </div>

                <!-- Approach -->
                <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">Food for thought</h6>
                    </div>
                    <div class="card-body">
                        <p></p>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <!-- /.container-fluid -->

</div>
<!-- End of Main Content -->

</div>
<!-- End of Content Wrapper -->
  </div>
</template>

<script>

import axios from 'axios' 
import ActivityCard from '@/components/ActivityCard';
import AthletePrs from '@/components/AthletePrs';
import DevelopmentOverTime from '@/components/DevelopmentOverTime';
import UrrisComment from '@/components/UrrisComment';
export default {
  name: 'AthleteDashboard',
  mounted() {
    axios.get('api/athletedash')
      .then(response => {
        
        this.athlete = response.data;
        this.fivekPr = this.$formatTime(parseInt(response.data.prs.filter(pr => pr.event === '5')[0].record));
        this.tenkPr = this.$formatTime(parseInt(response.data.prs.filter(pr => pr.event === '10')[0].record));
        this.halfMarathonkPr = this.$formatTime(parseInt(response.data.prs.filter(pr => pr.event === '21.1')[0].record));
        this.marathonkPr = this.$formatTime(parseInt(response.data.prs.filter(pr => pr.event === '42.2')[0].record));
        
      })
      .catch(error => {
        console.log(error);
      });
  },
  components: {
    ActivityCard,
    AthletePrs,
    DevelopmentOverTime,
    UrrisComment
  },
  props: {
  },
  data() {
    return {
      athlete: {},
      fivekPr: String,
      tenkPr: String,
      halfMarathonkPr: String,
      marathonkPr: String,
    }
  },
 
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<!-- activities: [
{
  "distance": 9.1123,
  "laps": [],
  "name": "Lunch Run",
  "pace": "06:03"
},
{
  "distance": 10.2038,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "04:34"
},
{
  "distance": 9.261899999999999,
  "laps": [],
  "name": "Lunch Run",
  "pace": "04:38"
},
{
  "distance": 3.2047,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "14:59"
},
{
  "distance": 3.0277,
  "laps": [],
  "name": "Afternoon Run",
  "pace": "08:14"
}
],
name: "Markus"

} -->
